import DomainApplication from "./DomainApplication";
import { Guid } from "guid-typescript";
import Site from "@/models/Site";

export default class Operation {
    public trackId: string;
    public id: string;
    public code: string;
    public name: string;
    public bopModelId: string;
    public operationFamilyId: any;
    public operationFamilyName: string;
    public operationFamilyPath: string;
    public unit: Object;
    public coefficients: Object;
    public operationType: number;
    public operationSiteTypeTravaux: Array<any>
  
    constructor(coefficients:Object,bopModelId:string,operationType:number) {
        this.id = Guid.create().toString();
        this.trackId = Guid.create().toString();
        this.code = '';
        this.name = '';
        this.bopModelId = bopModelId;
        this.operationFamilyId = null;
        this.operationFamilyName = "";
        this.operationFamilyPath = "";
        this.unit = {
            id: Guid.createEmpty().toString()
        };
        this.coefficients = coefficients;
        this.operationType = operationType;
        this.operationSiteTypeTravaux = [];

    }
  }
  