import { Guid } from 'guid-typescript';
import apiProvider from "@/utils/api";
import axios, { AxiosPromise } from "axios";
import {toUrl} from "@/utils/helpers"
const rootPath = 'units';

class UnitService {
  
    public async getUnits(): Promise<any> {
        return apiProvider.get(`${apiProvider.getUrl()}${rootPath}`);
    }
    
}

export default new UnitService();